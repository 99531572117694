import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import imgPartnerAccounts from '../images/perdiem_partneraccounts.png';
import useStyles from '../styles/styles';

const LandingPartnerAccounts = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <img src={imgPartnerAccounts} alt="Partner Accounts" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Collaborate with your partner
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            If you share your finances with a partner, the journey to FI is a collaboration. Each partner gets their own account, so you can each login safely and securely but share the same financial plan so there's no need to duplicate effort.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPartnerAccounts;