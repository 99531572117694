import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'

import DeleteIcon from '@mui/icons-material/Delete';

import useFetch from '../hooks/useFetch.js';

import BudgetEntries from './BudgetEntries.js';
import Transactions from './Transactions.js';
import IncomeStreams from './IncomeStreams.js';
import Dividends from './Dividends.js';

import SpendingByCategory from './SpendingByCategory.js';

import '../styles/Budget.css';
import eventBus from '../EventBus.js';

export default function Spending(props) {
    const [tabValue, setTabValue] = useState('transactions');
    const { data: budgets, setData: setBudgets } = useFetch('budgets');

    const createBudget = async () => {
        let token = localStorage.getItem('token');

        const res = await fetch(`${process.env.REACT_APP_API_URL}/budget/create`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });

        if (res.ok) {
            const json = res.json();

            setBudgets(prevRows => {
                return [...prevRows, json];
            });
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    return (
        <React.Fragment>
            <TabContext value={tabValue}>
                <TabList onChange={handleTabChange}>
                    <Tab label="Transactions" value='transactions' />
                    <Tab label="Spending Budget" value='budget' />
                    <Tab label="Income Streams" value='income' />
                    <Tab label="Dividends" value='dividends' />
                </TabList>
                <TabPanel value='transactions'>
                    <Transactions />
                </TabPanel>
                <TabPanel value='budget'>
                    <BudgetEntries />
                </TabPanel>
                <TabPanel value='income'>
                    <IncomeStreams />
                </TabPanel>
                <TabPanel value='dividends'>
                    <Dividends />
                </TabPanel>
            </TabContext>
        </React.Fragment>
    );
}