
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Typography from '@mui/material/Typography';

import useFetch from '../hooks/useFetch';
import eventBus from '../EventBus.js';
import { useEffect, useState } from 'react';

export default function SpendingByCategory(props) {
  const [data, setData] = useState([]);
  //const { data } = useFetch(`transactions/spending-report/category?begin=${props.begin}&end=${props.end}`);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');

      let url = `${process.env.REACT_APP_API_URL}/transactions/spending-report/category`;

      if (props.begin && props.end) {
        url += `?begin=${props.begin}&end=${props.end}`;
      }

      const res = await fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });

      if (res.ok) {
        const json = await res.json();
        setData(json);
      }
      else if (res.status == 401) {
        eventBus.dispatch("sessionExpired");
      }
    }

    fetchData();

    return () => {
      setData([]);
    }
  }, [props.begin, props.end]);

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="category" angle={-15} textAnchor="end" height={100} interval={0} label={props => <Typography width={30}>{props.text}</Typography>} />
        <YAxis domain="[0, 'auto']" />
        <Tooltip />
        <Legend verticalAlign='top' />
        <Bar name="Budget" dataKey="budget" fill="#8884d8" />
        <Bar name="Current" dataKey="current" stackId="a" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  )
}