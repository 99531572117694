import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import fireChart from '../images/perdiem_fire_chart.png';
import useStyles from '../styles/styles';

const Hero = ({ earlyAccessRef }) => {
  const classes = useStyles();

  return (
    <Box className={classes.heroBox}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12}>
          <Typography variant="h2" fontWeight={700} className={classes.title}>
            Begin the journey to financial freedom
          </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.subtitle}>
            Finally, a complete personal financial management suite purpose-built for the FIRE community.
            Link your accounts and answer a few simple questions about your goals. Then Per Diem
            will predict when you can retire and send you daily
            notifications to help keep you on track. <strong>Because every day matters.</strong>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: '16px' }}
            onClick={() => { earlyAccessRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); }}
          >
            REQUEST EARLY ACCESS
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={fireChart} alt="FIRE plan" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;