
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';

import EventDescription from './EventDescription.js';

import useFetch from '../hooks/useFetch.js';

export default function Events() {
    const { data } = useFetch('fire/events');

    const formatCurrency = (value) => {
        let formatted = value?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        });

        return formatted;
    }

    const dateFormatter = new Intl.DateTimeFormat('en-US');

    return (
        <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Event</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(d =>
                        <TableRow key={d.id}>
                            <TableCell>{d.type.replaceAll('_', ' ')}</TableCell>
                            <TableCell>{dateFormatter.format(new Date(d.createdAt))}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}