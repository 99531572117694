import { useState, Fragment, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import NavBar from './NavBar.js';

import Avatar from '@mui/material/Avatar';
import FIWidget from './FIWidget.js';

import Widgets from './Widgets.js';
import Goals from './Goals.js';
import Spending from './Spending.js';
import Copyright from './Copyright.js';

import Profile from './Profile.js';
import FirePlan from './FirePlan.js';

import { deepPurple } from '@mui/material/colors';
import Assets from './Assets.js';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, drawerWidth }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, drawerWidth }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function Dashboard({ onLogout, user, onFirePlanUpdate }) {
    const [open, setOpen] = useState(true);
    const [value, setValue] = useState(0);
    const [anchorEl, setAnchorEl] = useState();
    const [drawerWidth, setDrawerWidth] =
        useState(window.innerWidth > 600 ? 240 : 0); // TODO remove constant

    useEffect(() => {
        const handleWindowResize = () => {
            setDrawerWidth(window.innerWidth > 600 ? 240 : 0);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const menuOpen = Boolean(anchorEl);

    const navigate = useNavigate();

    if(!user) {
        console.log('user is null');
        navigate('/');
    }

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <CssBaseline />
            <Box sx={{ display: 'flex' }}>
                <AppBar position="absolute" open={open} drawerWidth={drawerWidth}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Per Diem
                        </Typography>

                        <FIWidget />

                        <Avatar sx={{ bgcolor: deepPurple[500] }} onClick={handleAvatarClick}></Avatar>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => { navigate('/profile'); handleMenuClose(); }}>Profile</MenuItem>
                            <MenuItem onClick={onLogout}>Logout</MenuItem>
                        </Menu>
                        {/* <IconButton color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                    </Toolbar>
                </AppBar>

                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Drawer variant="permanent" open={open} drawerWidth={drawerWidth}>
                            <Toolbar
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    px: [1],
                                }}
                            >
                                <IconButton onClick={toggleDrawer}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Toolbar>
                            <Divider />
                            <NavBar />
                        </Drawer>
                    </Box>
                
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[850],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Container maxWidth="lg" sx={{ mt: 10, mb: 2 }}>
                        <Grid item xs={12}>
                            <Routes>
                                <Route path="dashboard" element={<Widgets user={user} />} />
                                <Route path="fireplan" element={<FirePlan onFirePlanUpdate={onFirePlanUpdate} />} />
                                <Route path="milestones" element={<Goals />} />
                                <Route path="networth" element={<Assets user={user} />} />
                                <Route path="activity" element={<Spending />} />
                                <Route path="profile" element={<Profile />} />
                            </Routes>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: 'block', sm: 'none' } }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);

                            switch (newValue) {
                                case 0:
                                    navigate('/dashboard');
                                    break;
                                case 1:
                                    navigate('/networth');
                                    break;
                                case 2:
                                    navigate('/fireplan');
                                    break;
                                case 3:
                                    navigate('/activity');
                                    break;
                                case 4:
                                    navigate('/milestones');
                                    break;
                            }
                        }}
                    >
                        <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
                        <BottomNavigationAction label="Net Worth" icon={<AccountBalanceIcon />} />
                        <BottomNavigationAction label="FIRE Plan" icon={<LocalFireDepartmentIcon />} />
                        <BottomNavigationAction label="Activity" icon={<CurrencyExchangeIcon />} />
                        <BottomNavigationAction label="Milestones" icon={<TrackChangesIcon />} />
                    </BottomNavigation>
                </Paper>
            
        </Fragment>
    );
}

export default Dashboard;