import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import imgBudgeting from '../images/perdiem_perdiembudgeting.png';
import useStyles from '../styles/styles';

const LandingPerDiemBudgeting = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Per Diem&trade; budgeting keeps you on track
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            See how much you can spend on discretionary expenses per day using our unique budgeting method that keeps you on track for your FIRE plan. After accounting for your income, savings, retirement contributions, and mandatory expenses such as rent and utilities, your remaining monthly spend is divided by the remaining days in the month so you know exactly how much you have left to play with. Tighten the belt or treat yo' self depending on how you're doing.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <img src={imgBudgeting} alt="Per Diem Budgeting" className={classes.largeImage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPerDiemBudgeting;