import React, { useEffect, useState } from 'react';

import CountUp from 'react-countup';

import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import useFetch from '../hooks/useFetch';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function preventDefault(event) {
    event.preventDefault();
}

export default function Balance(props) {
    const { data: metrics, loading, error } = useFetch('periods/current');

    const formatCurrency = (value) => {
        return Number(value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        })
    }

    return (
        <Card sx={{ minWidth: 235 }}>
            <CardContent>
                <Typography
                    color="textSecondary"
                    textAlign="center"
                >
                    PER DIEM
                </Typography>
                <Typography
                    color="textPrimary"
                    variant="h2"
                    textAlign="center"
                >
                    {formatCurrency(metrics?.perDiem)}
                </Typography>
                {/* <Stack>
                    <Stack direction="row">
                        <ArrowUpwardIcon color="success" />
                        <Typography
                            sx={{
                                mr: 1
                            }}
                            variant="body2"
                        >
                            $12
                        </Typography>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            since yesterday
                        </Typography>
                    </Stack>
                </Stack> */}
            </CardContent>
        </Card>
    );
}