import React, { useEffect, useState } from "react";

import Title from './Title';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";

import Switch from '@mui/material/Switch';

import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function Profile(props) {

    const [profile, setProfile] = useState({});
    const [spouseProfile, setSpouseProfile] = useState();

    const [fireType, setFireType] = useState('dynamic');

    const [spouseFireType, setSpouseFireType] = useState('dynamic');
    const [spouseTargetFireYear, setSpouseTargetFireYear] = useState();

    const [spouseEmail, setSpouseEmail] = useState();

    const [deleteSpouseDialogOpen, setDeleteSpouseDialogOpen] = useState(false);

    const token = localStorage.getItem('token');

    const onProfileSaveClick = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/profiles/${profile.id}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(profile)
        });
    }

    const onSpouseProfileSaveClick = async () => {

        const idRoutePart = spouseProfile.id == null ? '' : `/${spouseProfile.id}`;

        const res = await fetch(`${process.env.REACT_APP_API_URL}/profiles${idRoutePart}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: (spouseProfile.id == null ? 'POST' : 'PUT'),
            body: JSON.stringify(spouseProfile)
        });

        if (res.ok) {
            const resBody = await res.json();

            setSpouseProfile(resBody);
        }
    }

    const onProfileFirstNameChange = (e) => {
        let newProfile = { ...profile };
        newProfile.firstName = e.target.value;
        setProfile(newProfile);
    }

    const onProfileLastNameChange = (e) => {
        let newProfile = { ...profile };
        newProfile.lastName = e.target.value;
        setProfile(newProfile);
    }

    const onBirthYearChange = (e) => {
        let newProfile = { ...profile };
        newProfile.birthYear = e.target.value;
        console.log(e.target);
        setProfile(newProfile);
    }

    const onFireYearChange = (e) => {
        let newProfile = { ...profile };
        newProfile.targetFireYear = e.target.value;
        setProfile(newProfile);
    }

    const onFireYearTypeChange = (event) => {
        if (event.target.checked) {
            setFireType('target');
            let newProfile = { ...profile };
            newProfile.fireType = 'target';
            setProfile(newProfile);
        }
        else {
            setFireType('dynamic');
            let newProfile = { ...profile };
            newProfile.targetFireYear = null;
            newProfile.fireType = 'dynamic';
            setProfile(newProfile);
        }
    }

    const onSpouseFireYearTypeChange = (event) => {
        if (event.target.checked) {
            setSpouseFireType('target');
        }
        else {
            setSpouseFireType('dynamic');
            setSpouseTargetFireYear(null);
        }
    }

    const onSpouseEmailChange = (e) => {
        setSpouseEmail(e.target.value);
    }

    const onSendInviteClick = async (e) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/invites`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                id: spouseProfile.id,
                email: spouseEmail
            })
        });
    }

    const onAddSpouseClick = (event) => {
        setSpouseProfile({
            type: 'spouse'
        });
    }

    const onDeleteSpouseClick = (event) => {
        setSpouseProfile(null);
        setDeleteSpouseDialogOpen(false);
    }

    useEffect(() => {
        console.log('fetching profiles');
        fetch(`${process.env.REACT_APP_API_URL}/profiles`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => {
                if (res.ok) {
                    res.json()
                        .then(profiles => {
                            let primary = profiles.find(p => p.type == 'primary');
                            if (primary == null) {
                                primary = {
                                    firstName: '',
                                    lastName: ''
                                };
                            }
                            setProfile(primary);
                            const spouse = profiles.find(p => p.type == 'spouse');
                            setSpouseProfile(spouse);
                        });
                }
            });

        return () => {
            setProfile({});
            setSpouseProfile(null);
        }
    }, []);

    const openDeleteSpouseDialog = () => {
        setDeleteSpouseDialogOpen(true);
    }

    const onCancelDeleteSpouseClick = () => {
        setDeleteSpouseDialogOpen(false);
    }

    const birthYears = [];
    for (var i = new Date().getFullYear(); i > 1900; i--) {
        birthYears.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }

    return (
        <Stack spacing={2}>

            <Stack spacing={8} direction="row">
                <div>
                    <Title>Profile Settings</Title>
                    <Stack spacing={2} sx={{ width: 400 }}>
                        <TextField id="first-name" label="First name" variant="standard" value={profile.firstName || ''} onChange={onProfileFirstNameChange} />
                        <TextField id="last-name" label="Last name" variant="standard" value={profile.lastName || ''} onChange={onProfileLastNameChange} />
                        <FormControl fullWidth>
                            <InputLabel id="birth-year-label">Birth year</InputLabel>
                            <Select labelId="birth-year-label" onChange={onBirthYearChange} value={profile.birthYear || ''}>
                                {birthYears}
                            </Select>
                        </FormControl>
                        {/* <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Calculate FIRE year</Typography>
                            <Switch onChange={onFireYearTypeChange} />
                            <Typography>Target FIRE year</Typography>
                        </Stack> */}
                        {fireType == 'target' && <TextField id="target-fire-year" label="Target FIRE year" value={profile.targetFireYear || ''} onChange={onFireYearChange} variant="standard" />}
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={onProfileSaveClick}>Save</Button>
                            {spouseProfile == null && <Button variant="contained" onClick={onAddSpouseClick}>Add Spouse</Button>}
                        </Stack>
                    </Stack>
                </div>
                {spouseProfile != null && <div>
                    <Title>Spouse</Title>
                    <Stack spacing={2} sx={{ width: 400 }}>
                        <TextField id="first-name" label="First name" variant="standard" />
                        <TextField id="last-name" label="Last name" variant="standard" />
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Calculate FIRE year</Typography>
                            <Switch onChange={onSpouseFireYearTypeChange} />
                            <Typography>Target FIRE year</Typography>
                        </Stack>
                        {spouseFireType == 'target' && <TextField id="target-fire-year" label="Target FIRE year" value={spouseTargetFireYear} variant="standard" />}
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={onSpouseProfileSaveClick}>Save</Button>
                            <Button variant="outlined" color="error" onClick={openDeleteSpouseDialog}>Delete</Button>
                            <Dialog
                                open={deleteSpouseDialogOpen}
                                onClose={onCancelDeleteSpouseClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Delete spouse?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Deleting a spouse will disassociate spouse from all income streams.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={onCancelDeleteSpouseClick}>Cancel</Button>
                                    <Button onClick={onDeleteSpouseClick} autoFocus color="error">
                                        Delete Spouse
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Stack>
                        <TextField id="spouse-email" label="Spouse email" variant="standard" onChange={onSpouseEmailChange} />
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={onSendInviteClick}>Send Invite</Button>
                        </Stack>
                    </Stack>
                </div>}
            </Stack>
        </Stack >
    )
}