import React, { Fragment, useEffect, useState } from 'react';

import { useOutletContext } from "react-router-dom";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { DataGrid, useGridApiContext, GridToolbar } from '@mui/x-data-grid';
import useFetch from '../hooks/useFetch.js';
import eventBus from '../EventBus.js';

function EditCategoryLookup({ categories, id, field }) {
  const apiRef = useGridApiContext();

  const handleValueChange = (event, selected) => {
    apiRef.current.setEditCellValue({ id, field, value: selected });
  }

  return (
    <Autocomplete
      disablePortal
      openOnFocus
      autoHighlight
      autoComplete
      blurOnSelect
      options={categories}
      sx={{ width: 300 }}
      onChange={handleValueChange}
      renderInput={(params) => <TextField {...params} label="Type a category" />}
    />
  );
}

export default function Transactions({ size, handleNext }) {

  const { data: categories } = useFetch('budget/categories');

  const { data: transactions, setData: setTransactions } = useFetch('transactions?count=500');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [viewable, setViewable] = useState([]);

  const [editTransactionId, setEditTransactionId] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [menuOpen, setMenuOpen] = useState(false);

  const primaryCategories = [
    'GENERAL_MERCHANDISE',
    'FOOD_AND_DRINK',
    'GENERAL_SERVICES',
    'ENTERTAINMENT',
    'TRANSPORTATION',
    'PERSONAL_CARE',
    'TRAVEL',
    'HOME_IMPROVEMENT',
    'RENT_AND_UTILITIES',
    'LOAN_PAYMENTS',
    'MEDICAL',
    'BANK_FEES',
    'GOVERNMENT_AND_NON_PROFIT',
    'TRANSFER_IN',
    'TRANSFER_OUT',
  ];

  const handleBudgetCategoryClick = (e, id) => {
    setAnchorEl(e.currentTarget);
    setEditTransactionId(id);
    setMenuOpen(true);
  }

  const handleMenuClick = async (newValue) => {
    console.log('new value', newValue);

    if (editTransactionId) {
      console.log('for transaction ', editTransactionId);

      const res = await fetch(`${process.env.REACT_APP_API_URL}/transactions/${editTransactionId}`, {
        method: 'PATCH',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ budgetCategoryId: newValue })
      });

      if (res.status == 401) {
        eventBus.dispatch("sessionExpired");
      }

      // replace transaction in state
      const newRow = { ...transactions.find(t => t.id == editTransactionId), budgetCategory: categories.find(c => c.value == newValue) };
      const newTransactions = transactions.map(t => t.id == editTransactionId ? newRow : t);
      setTransactions(newTransactions);
    }

    handleMenuClose();
  }

  const handleMenuClose = () => {
    setMenuOpen(false);
    setEditTransactionId(null);
  }

  const paginate = () => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return transactions.slice(page * pageSize, (page + 1) * pageSize);
  }

  useEffect(() => {
    setViewable(paginate());
  }, [transactions, page, pageSize]);

  const token = localStorage.getItem('token');

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
      }
    },
    {
      field: 'account',
      headerName: 'Account',
      valueGetter: (params) => params.row.account.officialName || params.row.account.name,
      flex: 1
    },
    //{ field: 'merchantName', headerName: 'Merchant', width: 175 },
    {
      field: 'name',
      headerName: 'Description',
      flex: 3
    },
    {
      field: 'budgetCategory',
      headerName: 'Category',
      //type: 'singleSelect',
      valueGetter: (params) => params.row.budgetCategory.label,
      //valueSetter: (params) => { const budgetCategory = categories.find(c => c.label === params.value); return { ...params.row, budgetCategory } },
      renderEditCell: (params) => { return <EditCategoryLookup categories={categories} id={params.id} field="budgetCategory" /> },
      editable: true,
      flex: 2
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueFormatter: (params) => {
        return params.value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      },
      align: 'right'
    }
  ];

  const formatCurrency = (value) => {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  }

  // to title case
  // https://stackoverflow.com/a/196991/12431728
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  // return all text after first hyphen
  const formatCategoryName = (name) => {
    const hyphenIndex = name.indexOf(' - ');
    if (hyphenIndex > -1) {
      const shortened = name.substring(hyphenIndex + 3).trim();

      if (shortened == 'Other') {
        return name;
      }

      return shortened
    }
    return name;
  }

  const formatPrimaryCategory = (name) => {
    const parts = name.split('_');
    return parts.map(p => toTitleCase(p)).join(' ');
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  }

  return (
    <Fragment>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: size == 'small' ? 300 : 400 }}>
          <Table size="small" stickyHeader>
            {size != 'small' &&
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Date</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Description</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Category</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Amount</TableCell>
                </TableRow>
              </TableHead>
            }
            <TableBody>
              {viewable.map(b =>
                <TableRow key={b.id} sx={{ height: 53 }}>
                  <TableCell>{formatDate(b.date)}</TableCell>
                  <TableCell>{toTitleCase(b.name)}</TableCell>
                  {size != 'small' &&
                    <TableCell><Chip
                      onClick={(e) => handleBudgetCategoryClick(e, b.id)}
                      color={b.budgetCategory.plaidPrimary}
                      label={formatCategoryName(b.budgetCategory.label)}
                    ></Chip></TableCell>
                  }
                  <TableCell sx={{ textAlign: 'right' }}>{formatCurrency(b.amount)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {size != 'small' &&
          <TablePagination
            component="div"
            count={transactions.length}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value, 10))}
          />
        }
      </Paper>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          dense: true
        }}
        sx={{
          '& div .Mui-disabled': { opacity: 1.0 }
        }}
      >
        {
          primaryCategories.map(p =>
            <div key={p}>
              <MenuItem sx={{ fontWeight: 'bold' }} disabled>{formatPrimaryCategory(p)}</MenuItem>
              {categories.filter(c => c.plaidPrimary == p).map(c =>
                <MenuItem key={c.value} onClick={() => handleMenuClick(c.value)}>{formatCategoryName(c.label)}</MenuItem>
              )}
            </div>
          )
        }
      </Menu>
      {handleNext &&
        <Grid container justifyContent="flex-end" marginTop={4}>
          <Button variant="contained" onClick={handleNext} endIcon={<NavigateNextIcon />}>Continue</Button>
        </Grid>
      }
    </Fragment>
  );
}