import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const NavBar = () => {
  const [selectedRoute, setSelectedRoute] = React.useState('/');
  const navigate = useNavigate();

  const navigateTo = (route) => {
    setSelectedRoute(route);
    navigate(route);
  }

  return (
    <List component="nav">
      <nav aria-label="main mailbox folders">
        <ListItemButton selected={selectedRoute == '/dashboard'} onClick={() => navigateTo('/dashboard')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton selected={selectedRoute == '/fireplan'} onClick={() => navigateTo('/fireplan')}>
          <ListItemIcon>
            <LocalFireDepartmentIcon />
          </ListItemIcon>
          <ListItemText primary="FIRE" />
        </ListItemButton>
        <ListItemButton selected={selectedRoute == '/networth'} onClick={() => navigateTo('/networth')}>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Net Worth" />
        </ListItemButton>
        <ListItemButton selected={selectedRoute == '/activity'} onClick={() => navigateTo('/activity')}>
          <ListItemIcon>
            <CurrencyExchangeIcon />
          </ListItemIcon>
          <ListItemText primary="Cash Flow" />
        </ListItemButton>
        <ListItemButton selected={selectedRoute == '/milestones'} onClick={() => navigateTo('/milestones')}>
          <ListItemIcon>
            <TrackChangesIcon />
          </ListItemIcon>
          <ListItemText primary="Milestones" />
        </ListItemButton>
      </nav>
    </List>
  );
}

export default NavBar