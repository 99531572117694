import { useState, useEffect, useRef, useMemo, Fragment, createContext } from 'react';

import useWebSocket, { ReadyState } from 'react-use-websocket';

import { Routes, Route, useNavigate, useSearchParams, Navigate } from 'react-router-dom';

import eventBus from './EventBus.js';

import Dashboard from './components/Dashboard.js';

import * as Sentry from "@sentry/react";
import { datadogRum } from '@datadog/browser-rum';

import { GoogleOAuthProvider, googleLogout, GoogleLogin } from '@react-oauth/google';

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

import { red, pink, purple, indigo, deepPurple, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey } from '@mui/material/colors';

import Onboarding from './components/Onboarding.js';
import Landing from './components/Landing.js';

const FireContext = createContext(null);

function App() {
  const [user, setUser] = useState();

  const [birthYear, setBirthYear] = useState();
  const [fireProgress, setFireProgress] = useState(100);
  const [fiAge, setFiAge] = useState();

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL, {
    onOpen: () => {
      console.log('web socket open');
    },

    onMessage: e => {
      const message = JSON.parse(e.data)
      if (message.progress) {
        setFireProgress(message.progress * 100);
        if (message.progress == 1.0) {
          setFiAge(message.fiAge);
        }
      }
    },

    shouldReconnect: () => true
  });

  useEffect(() => {
    if (user) {
      const token = localStorage.getItem('token');

      fetch(`${process.env.REACT_APP_API_URL}/fire/plan`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
        .then(res => {
          if (res.ok) {
            res.json().then(json => {
              const beginYear = json.beginYear;
              const profileBirthYear = json.household.people[0].birthYear;
              setBirthYear(profileBirthYear);

              if (fiAge == null) {
                setFiAge(beginYear - profileBirthYear);
              }
            })
          }
        });
    }
  }, [user]);

  const onFirePlanUpdate = (beginYear) => {
    setFiAge(beginYear - birthYear);
  }

  useEffect(() => {
    if (user && readyState == ReadyState.OPEN) {
      sendJsonMessage({ userId: user.id, body: 'fire' });
    }
  }, [user, readyState]);

  const navigate = useNavigate();

  const onUser = (user) => {
    console.log('on user');
    console.log('onboardingStep', user);

    setUser(user);

    if (user.person.household.onboardingStep == 6) {
      navigate('/dashboard');
    }
    else {
      navigate('/get-started');
    }
  };

  const onLogout = () => {
    console.log('logging out');
    setUser(null);
    googleLogout();
    // setModalOpen(false);
    setFiAge(null);
    setFireProgress(0);
    localStorage.removeItem('token');
    navigate('/');
  }

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { palette } = createTheme();
  let mdTheme = useMemo(
    () =>
      createTheme({
        palette: {
          //mode: prefersDarkMode ? 'dark' : 'light',
          RENT_AND_UTILITIES: palette.augmentColor({ color: purple }),
          INCOME: palette.augmentColor({ color: lightGreen }),
          GENERAL_SERVICES: palette.augmentColor({ color: pink }),
          TRANSFER_IN: palette.augmentColor({ color: grey }),
          FOOD_AND_DRINK: palette.augmentColor({ color: red }),
          TRANSFER_OUT: palette.augmentColor({ color: grey }),
          MEDICAL: palette.augmentColor({ color: blue }),
          ENTERTAINMENT: palette.augmentColor({ color: teal }),
          GOVERNMENT_AND_NON_PROFIT: palette.augmentColor({ color: blueGrey }),
          TRAVEL: palette.augmentColor({ color: green }),
          RENT_AND_UTILITIES: palette.augmentColor({ color: yellow }),
          GENERAL_MERCHANDISE: palette.augmentColor({ color: orange }),
          BANK_FEES: palette.augmentColor({ color: blueGrey }),
          HOME_IMPROVEMENT: palette.augmentColor({ color: brown }),
          LOAN_PAYMENTS: palette.augmentColor({ color: lime }),
          TRANSPORTATION: palette.augmentColor({ color: cyan }),
          PERSONAL_CARE: palette.augmentColor({ color: indigo }),
        },
      }),
    //[prefersDarkMode],
  );

  mdTheme = responsiveFontSizes(mdTheme);

  useEffect(() => {
    eventBus.on("sessionExpired", () => {
      onLogout();
    });

    // returned function will be called on component unmount 
    return () => {
      eventBus.remove("sessionExpired");
    }
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <FireContext.Provider value={{ fireProgress, readyState, fiAge }}>
          <Routes>
            {/* <Route
            path="/"
            render={() => {
              return (
                user == null ?
                  <Navigate to="/" /> :
                  user.person.household.onboardingStep == 6 ?
                    <Navigate to="/home" /> :
                    <Navigate to="/get-started" />
              )
            }}
          /> */}
            <Route path="/" element={<Landing onUser={onUser} />} />
            <Route path="/get-started/*" element={<Onboarding user={user} onLogout={onLogout} />} />
            <Route path="/*" element={<Dashboard user={user} onFirePlanUpdate={onFirePlanUpdate} onLogout={onLogout} />} />
          </Routes>
        </FireContext.Provider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
export { FireContext };

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 350,
  border: '2px solid #000',
  backgroundColor: 'white',
  boxShadow: 24,
  p: 4,
};