import {
  AppBar,
  Typography,
  Link,
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Drawer,
} from '@mui/material';
import { useState, cloneElement } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from '../styles/styles.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Login from './Login.js';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = ({ onUser }) => {
  const classes = useStyles();

  const [loginVisible, setLoginVisible] = useState(false);
  const [loginOrSignUp, setLoginOrSignUp] = useState('Sign In');

  const onLoginClick = () => {
    setLoginOrSignUp('Sign In');
    setLoginVisible(true);
  }

  const onSignUpClick = () => {
    setLoginOrSignUp('Sign Up');
    setLoginVisible(true);
  }

  const onModalClose = () => {
    setLoginVisible(false);
  }

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <Link underline="none" onClick={onLoginClick}>
            <Typography className={classes.link}>Login</Typography>
          </Link>
        </ListItem>
        <ListItem>
          <Link underline="none" onClick={onSignUpClick}>
            <Typography className={classes.link}>Sign Up</Typography>
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ marginBottom: '70px' }}>
      <ElevationScroll>
        <AppBar>
          <Toolbar className={classes.toolBar}>
            <Link href="#" underline="none">
              <Typography variant="h5" className={classes.logo}>
                Per Diem
              </Typography>
            </Link>

            {matches ? (
              <Box>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer('right', true)}
                >
                  <MenuIcon className={classes.menuIcon} fontSize="" />
                </IconButton>

                <Drawer
                  anchor="right"
                  open={state['right']}
                  onClose={toggleDrawer('right', false)}
                >
                  {list('right')}
                </Drawer>
              </Box>
            ) : <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: '0.1',
              }}
            >
              
              <Link underline="none" onClick={onLoginClick}>
                <Typography className={classes.link}>Login</Typography>
              </Link>
              <Link underline="none" onClick={onSignUpClick}>
                <Typography className={classes.link}>Sign Up</Typography>
              </Link>
            </Box>}

          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <Login onUser={onUser} visible={loginVisible} headerText={loginOrSignUp} onModalClose={onModalClose} />
    </Box>
  );
};

export default Header;