
import { useState, useEffect, useContext } from 'react';

import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import DrawSchedule from './DrawSchedule.js';
import Events from './Events.js';
import Chart from './Chart.js';
import eventBus from '../EventBus.js';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { FireContext } from '../App.js';
import { Typography } from '@mui/material';

import useStyles from '../styles/styles.js';

export default function FirePlan({ onFirePlanUpdate }) {
    const classes = useStyles();

    const [tabValue, setTabValue] = useState('fire-plan');
    const [cashFlows, setCashFlows] = useState([]);
    const [fireYear, setFireYear] = useState();
    const [threshold, setThreshold] = useState(0.80);

    const { fiAge } = useContext(FireContext);

    const token = localStorage.getItem('token');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const onThresholdChange = async (value) => {
        value /= 100.0;

        const res = await fetch(`${process.env.REACT_APP_API_URL}/fire/threshold`, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ threshold: value })
        });

        if (res.ok) {
            setThreshold(value);

            await getFirePlan();
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const getFirePlan = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/fire/plan`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        if (res.ok) {
            const json = await res.json();

            setCashFlows(json.ledgerEntries);
            setThreshold(json.threshold);

            setFireYear(json.beginYear);
            onFirePlanUpdate(json.beginYear);

            console.log('peak net worth', Math.max(...json.ledgerEntries.map(l => l.netWorth)));
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    useEffect(() => getFirePlan(), [fiAge]);

    return (
        <TabContext value={tabValue}>
            <TabList onChange={handleTabChange}>
                <Tab label="FIRE Plan" value='fire-plan' />
                <Tab label="Draw Schedule" value='draw-schedule' />
                <Tab label="Impactful Events" value='events' />
            </TabList>
            <TabPanel value='fire-plan'>
                {fiAge < 0 &&
                    <Grid container spacing={0} marginBottom={1}>
                        <Grid item xs={12}>
                            <Card
                                sx={{ height: '100%' }}
                            >
                                <CardContent>
                                    <Typography>
                                        Based on the information you’ve entered, you do not currently have a path to financial independence (FI). Here are some things to double check and consider changing.
                                    </Typography>
                                    <Typography variant="body2">
                                        <ol>
                                            <li>Have you connected all of your accounts, including investment accounts?</li>
                                            <li>Double check your income streams. Were they imported accurately? Are there any future income streams not accounted for, such as social security or a pension?</li>
                                            <li>Check your budget. Are there any areas (especially discretionary categories such as restaurants or travel), that you can cut back on to increase your savings rate?</li>
                                            <li>Have you entered all known upcoming milestones? For example, a category of spending that you expect to drop off in the future — such as paying off a mortgage or your kids finishing college? Or a one-time cash windfall such as an inheritance?</li>
                                            <li>Are you willing to increase your risk tolerance? By default, it is set to 90%, which is rather conservative. If you are willing to be flexible in case the market performs poorly — such as lowering spending or going back to work temporarily — then you can lower this threshold.</li>
                                        </ol>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                }
                <Chart cashFlows={cashFlows} fireYear={fireYear} successThreshold={threshold} onThresholdChange={onThresholdChange} />
            </TabPanel>
            <TabPanel value='draw-schedule'>
                <DrawSchedule />
            </TabPanel>
            <TabPanel value='events'>
                <Events />
            </TabPanel>
        </TabContext>
    );
}