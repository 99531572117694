import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import imgRealAccounts from '../images/perdiem_realaccounts.png';
import useStyles from '../styles/styles';

const LandingRealData = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <img src={imgRealAccounts} alt="Real Accounts" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Real data, real time
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            No more spreadsheets, no more guesswork. Your data is piped into Per Diem from your real accounts, safely and securely using a trusted vendor that powers the most popular financial applications. Every transaction, every change in market data is used to recalculate your FI Age and we will notify you when you are off track.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: '16px' }}
            href="https://plaid.com/what-is-plaid/"
            target="_blank"
          >
            SEE HOW IT WORKS
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingRealData;