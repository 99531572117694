import { DataGrid } from "@mui/x-data-grid"

import useFetch from '../hooks/useFetch.js';

const Dividends = () => {

    const { data: dividends } = useFetch('income/dividends');

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            type: 'date',
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleDateString('en-US');
            },
            flex: 1
        },
        {
            field: 'security',
            headerName: 'Security',
            valueGetter: (params) => `${params.row.security.name} (${params.row.security.tickerSymbol})`,
            flex: 3
        },
        {
            field: 'amount',
            headerName: 'Amount',
            valueGetter: (params) => {
                return Number(-params.row.amount).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 2
                });
            },
            align: 'right',
            flex: 1
        }
    ];

    return (
        <DataGrid
            autoHeight
            rows={dividends}
            columns={columns}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }]
                },
            }}
        />
    )
}

export default Dividends;