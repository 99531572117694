import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import useStyles from '../styles/styles';

const ContactUs = ({ earlyAccessRef }) => {
  const [email, setEmail] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [fullName, setFullName] = useState('');
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const submitForm = (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_API_URL}/invites/request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, twitterHandle, fullName, message }),
    })
      .then(res => { alert('Invite request received. We will be in touch!'); });
  };

  return (
    <Grid alignItems="center" justifyContent="center" container className={classes.formContainer}>
      <div ref={earlyAccessRef}></div>
      <Typography variant="h4" className={classes.formHeading}>
        Request early access
      </Typography>
      <Grid item xs={12}
        className={classes.form}
        component="form"
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Full Name"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />

        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          label="Twitter Handle"
          variant="outlined"
          fullWidth
          className={classes.inputField}
          value={twitterHandle}
          onChange={(e) => setTwitterHandle(e.target.value)}
        />

        <TextareaAutosize
          aria-label="minimum height"
          minRows={6}
          placeholder="Tell us a little about yourself"
          className={classes.textArea}
          spellCheck
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ width: '200px', fontSize: '16px' }}
          onClick={submitForm}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactUs;