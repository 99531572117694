import { useState, useEffect, useContext, Fragment } from 'react';

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Zoom from '@mui/material/Zoom';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';

import { Link as RouterLink } from "react-router-dom";

import { ReadyState } from 'react-use-websocket';

import { FireContext } from '../App';

const OnboardingComplete = () => {
    const { fireProgress, fiAge, readyState } = useContext(FireContext);

    const [modalOpen, setModalOpen] = useState(false);

    const token = localStorage.getItem('token');

    useEffect(() => {
        if (fireProgress == 100) {
            setModalOpen(true);
        }
    }, [fireProgress]);

    useEffect(() => {
        if (readyState == ReadyState.OPEN) {
            fetch(`${process.env.REACT_APP_API_URL}/fire/events`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ event: 'onboarding_completed' }),
            });
        }
        else {
            console.log('ready state not open', readyState);
        }
    }, [readyState]);

    return (
        <Fragment>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="5em"
                position="relative"
            >
                <Box width="60%" height="10em">
                    <Fragment>
                        <Typography align="center" marginBottom="0.5em">
                            Calculating your FIRE plan...
                        </Typography>
                        <LinearProgress variant="determinate" value={fireProgress} sx={{ height: 20, borderRadius: 2 }} />
                    </Fragment>
                </Box>
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                closeAfterTransition
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Zoom in={modalOpen} timeout={750} style={{ transitionDelay: '500ms' }}>
                    {fiAge > 0 ?
                        <Box sx={modalStyle}>
                            <Typography variant="h6">
                                Your FI age is
                            </Typography>
                            <Typography id="transition-modal-title" variant="h1" component="h1">
                                {fiAge}
                            </Typography>
                            <Link component={RouterLink} to="/fireplan">View your FIRE plan</Link>
                        </Box>
                        :
                        <Box sx={modalStyle}>
                            <Typography variant="h5" sx={{ textAlign: 'center', mb: 5 }}>
                                You are not currently on a path to financial independence.
                            </Typography>
                            <Link component={RouterLink} to="/fireplan">Let's figure out why</Link>
                        </Box>
                    }
                </Zoom>
            </Modal>
        </Fragment>
    )
}

const modalStyle = {
    position: 'absolute',
    top: '30%',
    margin: 'auto',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
};

export default OnboardingComplete;