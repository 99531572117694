
import { useState, useEffect } from "react";

import { useOutletContext } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const OnboardingProfile = ({ handleNext }) => {

    const [firePlanId, setFirePlanId] = useState();

    const [birthYear, setBirthYear] = useState('');
    const [birthYearError, setBirthYearError] = useState();

    const [lifeExpectancy, setLifeExpectancy] = useState(95);
    const [lifeExpectancyError, setLifeExpectancyError] = useState();

    const [estateValue, setEstateValue] = useState('0');

    const [threshold, setThreshold] = useState(0.9);

    const [endYear, setEndYear] = useState();

    const token = localStorage.getItem('token');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/profiles`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => {
                if (res.ok) {
                    res.json()
                        .then(profiles => {
                            const primary = profiles.find(p => p.type == 'primary');
                            if (primary) {
                                setBirthYear(primary.birthYear || '');
                            }
                        });
                }
            });

        fetch(`${process.env.REACT_APP_API_URL}/fire/plan`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => {
                if (res.ok) {
                    res.json()
                        .then(firePlan => {
                            setFirePlanId(firePlan.id);
                            setThreshold(firePlan.threshold);
                            setEstateValue(formatNumber(firePlan.desiredEstateValue));
                            setEndYear(firePlan.endYear);
                        });
                }
            });

        return () => {
            setBirthYear('');
            setThreshold('');
            setEstateValue('');
        }
    }, []);

    useEffect(() => {
        if (endYear && birthYear) {
            setLifeExpectancy(endYear - birthYear);
        }
    }, [endYear])

    const saveProfile = async () => {
        setBirthYearError(birthYear == '' ? 'Birth year is required' : null)
        setLifeExpectancyError(lifeExpectancy == '' ? 'Life expectancy is required' : null)

        if(birthYear == '' || lifeExpectancy == '') {
            console.log('error');
            return;
        }

        console.log('no errors');

        const desiredEstateValue = estateValue.replace(/[^\d.-]/g, '');

        const payload = {
            threshold,
            birthYear,
            desiredEstateValue,
            lifeExpectancy
        }

        let url = `${process.env.REACT_APP_API_URL}/fire/plan`;
        if (firePlanId) {
            url += '/' + firePlanId;
        }

        const res = await fetch(url, {
            method: firePlanId ? 'PUT' : 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        if (res.ok && handleNext) {
            handleNext();
        }
    }

    const formatNumber = (value) => {
        let formatted = Number(value.replace(/[^\d.-]/g, '') || '').toLocaleString('en-US', {
            currency: 'USD',
            maximumFractionDigits: 0
        });

        return formatted;
    }

    const handleEstateValueChange = (e) => {
        setEstateValue(formatNumber(e.target.value));
    }

    return (
        <Box marginTop={5}>
            <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
            >
                <Card sx={{ width: 235 }}>
                    <CardContent>
                        <Typography gutterBottom>
                            Birth year
                        </Typography>
                        <TextField
                            value={birthYear}
                            onBlur={(e) => setBirthYearError(e.target.value == '' ? 'Birth year is required' : null)}
                            required
                            onChange={(e) => { setBirthYear(e.target.value) }}
                            error={birthYear == ''}
                            helperText={birthYearError}
                        ></TextField>
                        <FormHelperText>We never share your data. This is only used to calculate your FI age and estimate social security.</FormHelperText>
                    </CardContent>
                </Card>
                <Card sx={{ width: 235 }}>
                    <CardContent>
                        <Typography gutterBottom>
                            Life expectancy
                        </Typography>
                        <TextField
                            value={lifeExpectancy}
                            onBlur={(e) => setLifeExpectancyError(e.target.value == '' ? 'Life expectancy is required' : null)}
                            required
                            error={lifeExpectancy == ''}
                            helperText={lifeExpectancyError}
                            onChange={(e) => { setLifeExpectancy(e.target.value) }}
                        ></TextField>
                        <FormHelperText>This is what we use to ensure that your money won't run out when calculating your FIRE plan. So, err on the high side.</FormHelperText>
                    </CardContent>
                </Card>
                <Card sx={{ width: 235 }}>
                    <CardContent>
                        <Typography gutterBottom>
                            Min estate value
                        </Typography>
                        <OutlinedInput value={estateValue} onChange={handleEstateValueChange} variant="outlined" startAdornment={<InputAdornment position="start">$</InputAdornment>}></OutlinedInput>
                        <FormHelperText>How much, if any, you want remaining in your estate (e.g. inheritance for the kids, charitable trust, etc).</FormHelperText>
                    </CardContent>
                </Card>
                <Card sx={{ width: 235 }}>
                    <CardContent>
                        <Typography gutterBottom>
                            Risk threshold
                        </Typography>
                        <Select
                            fullWidth
                            value={threshold}
                            onChange={(e) => setThreshold(e.target.value)}
                        >
                            <MenuItem value={0.9}>Low Risk - 90%</MenuItem>
                            <MenuItem value={0.75}>Medium Risk - 75%</MenuItem>
                            <MenuItem value={0.6}>High Risk - 60%</MenuItem>
                        </Select>
                        <FormHelperText>How sure do you need to be that your net worth will not fall below your min. estate value once you've achieved financial independence?</FormHelperText>
                    </CardContent>
                </Card>
            </Stack>
            <Grid container justifyContent="flex-end" marginTop={4}>
                <Button variant="contained" onClick={saveProfile} endIcon={<NavigateNextIcon />}>Continue</Button>
            </Grid>
        </Box>
    );
}

export default OnboardingProfile;