import { useEffect, useState } from 'react';

import { ComposedChart, Bar, Line, XAxis, YAxis, ReferenceLine, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export default function Chart({ cashFlows, fireYear, successThreshold, onThresholdChange }) {
  const [threshold, setThreshold] = useState(successThreshold);

  useEffect(() => {
    setThreshold(successThreshold);
  }, [successThreshold]);

  const percentFormatter = (value) => {
    let formatted = value.toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 0
    });

    return formatted;
  }

  const onSliderChange = (event, value) => {
    setThreshold(value / 100);
  }

  const onSliderChangeCommitted = (event, value) => {
    onThresholdChange && onThresholdChange(value);
  }

  const currencyFormatter = (value) => {
    let formatted = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    });

    return formatted;
  }

  const tooltipFormatter = (value, name) => {
    if (name === 'Success Rate') {
      return percentFormatter(value);
    }

    return currencyFormatter(value);
  }

  return (
    <Stack direction="row">
      <ResponsiveContainer width="95%" height={460}>
        <ComposedChart
          data={cashFlows}
          stackOffset="sign"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="year" />
          <YAxis orientation='left' yAxisId='left' tickFormatter={currencyFormatter} />
          <YAxis orientation='right' yAxisId='right' tickFormatter={percentFormatter} domain={[0,1]} />
          <ReferenceLine x={fireYear} yAxisId='right' label="FIRE" stroke="green" />
          <ReferenceLine y={successThreshold} yAxisId='right' stroke="green" />
          <Bar name="Income" dataKey="income" fill="#8884d8" stackId="a" yAxisId='left' />
          <Bar name="Expenses" dataKey="expenses" fill="#82ca9d" stackId="a" yAxisId='left' />
          <Bar name="Cash" dataKey="cash" stackId="a" fill="#7a5145" yAxisId='left' />
          <Bar name="Taxable" dataKey="taxable" stackId="a" fill="#7a5195" yAxisId='left' />
          <Bar name="Tax Deferred" dataKey="taxDeferred" stackId="a" fill="#374c80" yAxisId='left' />
          <Bar name="Tax Exempt" dataKey="taxExempt" stackId="a" fill="#bc5090" yAxisId='left' />
          {/* <Bar dataKey="yield" stackId="a" fill="#ff764a" yAxisId='left' /> */}
          <Bar name="Tax Paid" dataKey="taxPaid" stackId="a" fill="#ff764a" yAxisId='left' />
          <Line name='Success Rate' dataKey='successRate' label="Success Rate" yAxisId='right' dot={false} legendType="none" />
          <Tooltip formatter={tooltipFormatter} />
          <Legend verticalAlign='top' height={36} />
        </ComposedChart>
      </ResponsiveContainer>
      <Box sx={{ height: 370, mt: 7 }}>
        <Slider
          aria-label="Success Threshold"
          orientation="vertical"
          valueLabelDisplay="on"
          value={threshold * 100}
          onChange={onSliderChange}
          onChangeCommitted={onSliderChangeCommitted}
        />
      </Box>
    </Stack>
  );
}