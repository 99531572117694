import React, { useEffect, useState } from "react";

import eventBus from '../EventBus.js';

import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'

import { DataGrid } from '@mui/x-data-grid';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LinkStatus from "./LinkStatus.js";

import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

import useFetch from "../hooks/useFetch.js";
import Accounts from "./Accounts.js";

export default function Assets({ user }) {
    ChartJS.register(ArcElement, Tooltip, Legend, Title);

    const [netWorth, setNetWorth] = useState('Calculating...');
    const [accountAlloc, setAccountAlloc] = useState([]);
    const [securityAlloc, setSecurityAlloc] = useState([]);

    const token = localStorage.getItem('token');

    useFetch('holdings/allocations', null, (data) => {

        console.log(data);

        setAccountAlloc({
            labels: data.accountTypes.map(t => t.type),
            datasets: [
                {
                    label: 'account types',
                    data: data.accountTypes.map(t => t.total),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        });

        setSecurityAlloc({
            labels: data.securityTypes.map(t => t.type),
            datasets: [
                {
                    label: 'security types',
                    data: data.securityTypes.map(t => t.total),
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        });
    });

    const [holdings, setHoldings] = useState([]);
    const holdingCols = [
        {
            field: 'name',
            headerName: 'Name',
            valueGetter: (params) => params.row.security.name,
            flex: 3
        },
        {
            field: 'tickerSymbol',
            headerName: 'Symbol',
            valueGetter: (params) => params.row.security.tickerSymbol,
            flex: 1
        },
        {
            field: 'closePrice',
            headerName: 'Close Price',
            valueGetter: (params) => {
                return Number(params.row.security.closePrice).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 2
                });
            }
        },
        {
            field: 'quantity',
            headerName: 'Qty',
            valueGetter: (params) => {
                return Number(params.row.quantity).toLocaleString('en-US', {
                    maximumFractionDigits: 2
                });
            }
        },
        {
            field: 'value',
            headerName: 'Value',
            valueGetter: (params) => params.row.security.closePrice ? params.row.security.closePrice * params.row.quantity : params.row.institutionValue,
            valueFormatter: (params) => Number(params.value).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0
            })
        },
    ];

    const [tabValue, setTabValue] = useState('accounts');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const loadHoldings = () => {
        fetch(`${process.env.REACT_APP_API_URL}/holdings`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
                else if (res.status == 401) {
                    eventBus.dispatch("sessionExpired");
                    Promise.reject('session expired');
                }
            })
            .then(json => {
                setHoldings(json);
            });
    }

    useEffect(() => {
        loadHoldings();
    }, []);

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <Card
                        sx={{ height: '100%' }}
                    >
                        <CardContent>
                            <Grid
                                container
                                spacing={3}
                                sx={{ justifyContent: 'space-between' }}
                            >
                                <Grid item>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="overline"
                                    >
                                        NET WORTH
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="h4"
                                    >
                                        {netWorth}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {/* <Box
                                sx={{
                                    pt: 2,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <ArrowDownwardIcon color="error" />
                                <Typography
                                    color="error"
                                    sx={{
                                        mr: 1
                                    }}
                                    variant="body2"
                                >
                                    12%
                                </Typography>
                                <Typography
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Since last month
                                </Typography>
                            </Box> */}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <LinkStatus onStatusChange={() => {
                        console.log('status changed, fetching accounts...');
                        //accounts.current.loadAccounts(); TODO figure out how to call child component function, ref doesn't work here
                        loadHoldings();
                    }} />
                </Grid>
            </Grid>
            <TabContext value={tabValue}>
                <TabList onChange={handleTabChange}>
                    <Tab label="Accounts" value='accounts' />
                    <Tab label="Holdings" value='holdings' />
                    <Tab label="Allocations" value='allocations' />
                </TabList>
                <TabPanel value='accounts' sx={{ paddingX: { xs: '0px', sm: '24px' }}}>
                    <Accounts onNetWorthChange={setNetWorth} user={user} />
                </TabPanel>
                <TabPanel value='holdings'>
                    <div style={{ height: 450 }}>
                        <DataGrid
                            rows={holdings}
                            columns={holdingCols}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'value', sort: 'desc' }]
                                },
                            }}
                        />
                    </div>
                </TabPanel>
                <TabPanel value='allocations'>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <Pie
                                data={accountAlloc}
                                width={350}
                                height={350}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: 'Accounts',
                                            font: {
                                                size: 18
                                            }
                                        }
                                    }
                                }} />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Pie
                                data={securityAlloc}
                                width={350}
                                height={350}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: 'Assets',
                                            font: {
                                                size: 18
                                            }
                                        }
                                    }
                                }} />
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </React.Fragment>
    );
}