import CountUp from 'react-countup';

import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import { useContext } from 'react';

import { FireContext } from '../App';

export default function FIWidget() {

    const { fireProgress, fiAge } = useContext(FireContext);

    return (
        <Stack direction="row" spacing={3} marginRight={5}>
            <Stack>
                <Typography
                    color="white"
                    textAlign="center"
                >
                    FI AGE
                </Typography>
                {fireProgress > 0 &&
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress variant="determinate" value={fireProgress} />
                    </Box>
                }
            </Stack>
            <Typography
                color="white"
                variant="h5"
            >
                {fiAge ?
                    (fiAge > 0 ?
                        <CountUp end={fiAge} preserveValue={true} /> : <span>&infin;</span>) :
                    'TBD'
                }
            </Typography>
        </Stack>
    );
}