import { useRef, useState } from 'react';

import Header from './Header.js';
import Hero from './Hero.js';
import LandingThreeSections from './LandingThreeSections.js';
import LandingRealData from './LandingRealData.js';
import Testimonial from './Testimonial.js';
import ContactUs from './ContactUs.js';
import Footer from './Footer.js';
import LandingPerDiemBudgeting from './LandingPerDiemBudgeting.js';
import LandingPartnerAccounts from './LandingPartnerAccounts.js';
import LandingDrawSchedule from './LandingDrawSchedule.js';
import LandingInvestments from './LandingInvestments.js';
import LandingMonteCarlo from './LandingMonteCarlo.js';
import LandingOnboarding from './LandingOnboarding.js';

const Landing = ({ onUser }) => {

    const earlyAccessRef = useRef();

    return (
        <>
            {/* <CssBaseline /> */}
            <Header onUser={onUser} />
            <div style={{ display: 'none' }}>
                {/* <GoogleLogin
                    onSuccess={(res) => onLoginSuccess(res.credential)}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    useOneTap
                    auto_select
                /> */}
            </div>

            <Hero earlyAccessRef={earlyAccessRef} />
            <LandingThreeSections />
            <LandingMonteCarlo />
            <LandingOnboarding />
            <LandingRealData />
            <LandingInvestments />
            <LandingDrawSchedule />
            <LandingPerDiemBudgeting />
            <LandingPartnerAccounts />
            <Testimonial />
            {/* <stripe-pricing-table pricing-table-id="prctbl_1NMZOdGRvtkVdOvjmyEj2v6F"
                publishable-key="pk_live_51NMYzqGRvtkVdOvj37wxFhTsXURXH26Kuymr75oMMLRaJQIg70wpp0mwilSDJ0IYY8CBsvg755f2Il06Eo5t3Oqg005tZ6tScv">
              </stripe-pricing-table> */}
            <ContactUs earlyAccessRef={earlyAccessRef} />
            <Footer />
        </>
    )
}

export default Landing;