import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import imgMonteCarlo from '../images/perdiem_fire_chart.png';
import useStyles from '../styles/styles';

const LandingMonteCarlo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={6} order={{ md: 1, xs: 2}}>
          <img src={imgMonteCarlo} alt="Monte Carlo Simulations" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6} order={{ md: 2, xs: 1}}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Predict your FI age with Monte Carlo simulations
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            Whenever your financial data changes, we run 1000 simulations using historical market returns to update your FI age in real-time. You decide your risk threshold to optimize your retirement date depending on your required confidence level. See the impact of your actions or simulate potential decisions using our FI engine.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingMonteCarlo;