
import { Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart } from 'recharts';

import useFetch from '../hooks/useFetch';
import eventBus from '../EventBus.js';
import { useEffect, useState } from 'react';

export default function SpendingByDay() {
  const [data, setData] = useState([]);
  //const { data } = useFetch(`transactions/spending-report/category?begin=${props.begin}&end=${props.end}`);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');

      let targetPerDiem = 0;
      const now = new Date();
      const numDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

      let res = await fetch(`${process.env.REACT_APP_API_URL}/periods/current`, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });

      if (res.ok) {
        const json = await res.json();
        targetPerDiem = json.targetPerDiem;
      }


      let url = `${process.env.REACT_APP_API_URL}/transactions/spending-report/day`;

      // if (props.begin && props.end) {
      //   url += `?begin=${props.begin}&end=${props.end}`;
      // }

      res = await fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      });

      if (res.ok) {
        const json = await res.json();
        let sum = 0.0;
        let date = new Date(now);
        let i = 1;
        //const withSum = json.map(row => {
        let withSum = [];
        let jsonIter = 0;
        for (let i = 1; i < now.getUTCDate(); i++) {
          const row = json[jsonIter];
          if (!row) { break; }
          const dateObj = new Date(row.date);
          const day = dateObj.getUTCDate();
          //console.log(new Date(row.date));
          //console.log(day);
          if (day == i) {
            sum += row.amount;
            withSum.push({
              date: i,
              amount: row.amount,
              target: targetPerDiem * i,
              total: sum
            });
            jsonIter++;
          }
          else {
            date.setDate(day);
            withSum.push({
              date: i,
              amount: 0.0,
              target: targetPerDiem * i,
              total: sum
            });
          }
          //console.log(i);
          // console.log(sum);
          //console.log(targetPerDiem * i);
        }
        console.log(withSum);
        setData(withSum);
      }
      else if (res.status == 401) {
        eventBus.dispatch("sessionExpired");
      }
    }

    fetchData();

    return () => {
      setData([]);
    }
  }, []);

  return (
    <ResponsiveContainer width="100%" height={255}>
      <LineChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="date" />
        <YAxis />
        <Line dataKey="total" dot={false} />
        <Line dataKey="target" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}