import { useState, useEffect } from 'react';

import eventBus from '../EventBus.js';

function useFetch(url, initialValue = [], onFetchComplete) {
    const [data, setData] = useState(initialValue);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading('loading...');
        setError(null);

        let token = localStorage.getItem('token');

        fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setLoading(false);
                if (res.ok) {
                    res.json()
                        .then(json => {
                            setData(json);
                            if (onFetchComplete) {
                                onFetchComplete(json);
                            }
                        });
                }
                else if (res.status == 401) {
                    eventBus.dispatch("sessionExpired");
                }
            })
            .catch(err => {
                setLoading(false);
                setError('An error occurred. Awkward..');
            });
        
        return () => {
            setData(initialValue);
        }
    }, [url]);

    return { data, loading, error, setData };
}

export default useFetch;