import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import imgDrawSchedule from '../images/perdiem_drawschedule.png';
import useStyles from '../styles/styles';

const LandingDrawSchedule = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <img src={imgDrawSchedule} alt="Draw Schedule" className={classes.largeImage} />
        </Grid>

        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Tax-optimized IRA contribution and draw schedule
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            By default, we will curate a tax-optimized contribution and draw schedule into and our of your IRAs and other cash and investment accounts. View this plan in year-by-year list format or visualize in our FIRE chart.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingDrawSchedule;