import { Fragment, useEffect, useState } from 'react';

import { useOutletContext } from "react-router-dom";

import eventBus from '../EventBus.js';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function BudgetEntries({ handleNext }) {

  const [data, setData] = useState([]);

  const [budgetId, setBudgetId] = useState();
  const [editId, setEditId] = useState();
  const [editAmount, setEditAmount] = useState('');

  const [createMode, setCreateMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [addAmount, setAddAmount] = useState('');
  const [addCategoryId, setAddCategoryId] = useState(0);

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/budget/categories`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
      .then(res => res.json())
      .then(json => setCategories(json));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let res = await fetch(`${process.env.REACT_APP_API_URL}/budgets/entries`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      if (res.ok) {
        let json = await res.json();

        if (json.budget.id == null) {
          // no budget exists, create one
          console.log('no budget found, creating one');
          res = await fetch(`${process.env.REACT_APP_API_URL}/budgets`, {
            headers: {
              'Authorization': 'Bearer ' + token
            },
            method: 'POST'
          });

          if (res.ok) {
            json = await res.json();
          }
        }

        setBudgetId(json.budget.id);
        const filtered = json.rows.filter(r => r.budget > 0);

        setData(filtered);
      }

      else if (res.status == 401) {
        eventBus.dispatch("sessionExpired");
      }
    }

    fetchData()
      .catch(console.error);

    return () => {
      setData([]);
    }
  }, []);

  const onBudgetEntryEdit = (id, amount) => {
    setEditId(id);
    setEditAmount(amount);
  }

  const handleCancelCreate = () => {
    setAddCategoryId(0);
    setAddAmount('');
    setCreateMode(false);
  }

  const createClickHandler = async () => {

    const res = await fetch(`${process.env.REACT_APP_API_URL}/budgets/entries`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ budgetId, categoryId: addCategoryId, amount: addAmount })
    });

    if (res.ok) {
      const newEntry = await res.json();

      const forDisplay = {
        id: newEntry.budgetCategoryId,
        subcategory: categories.find(c => c.value == newEntry.budgetCategoryId).label,
        budget: newEntry.amount,
        current: 0
      }
      setData(prevData => [...prevData, forDisplay]);

      handleCancelCreate();
    }

  }

  const onBudgetEntryEditSave = async () => {
    const amount = editAmount;

    const url = `${process.env.REACT_APP_API_URL}/budgets/${budgetId}/entries/${editId}`;

    const res = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: amount })
    });

    if (res.ok) {
      // 1. Make a shallow copy of the items
      let budgetRows = [...data];

      // 2. Make a shallow copy of the item you want to mutate
      let rowIndex = budgetRows.findIndex(b => b.id == editId);
      let item = { ...budgetRows[rowIndex] };

      // 3. Replace the property you're intested in
      item.budget = amount;

      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      budgetRows[rowIndex] = item;

      // 5. Set the state to our new copy
      setData(budgetRows);

      handleCancelEdit();
    }
    else if (res.status == 401) {
      eventBus.dispatch("sessionExpired");
    }
  }

  const handleCancelEdit = () => {
    setEditId(null);
    setEditAmount('');
  }

  return (
    <Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Category</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Budget</TableCell>
            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>This Month</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(b =>
            <TableRow key={b.id} sx={{ height: 53 }}>
              <TableCell>{b.subcategory}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {
                  editId == b.id ?
                    <Input
                      size="small"
                      sx={{ width: '8.0em' }}
                      value={editAmount}
                      onChange={(e) => { setEditAmount(e.target.value) }}
                      inputProps={{ style: { textAlign: 'right' } }}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                    :
                    Number(b.budget).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 0
                    })
                }
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>{Number(b.current).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0
              })}</TableCell>
              <TableCell sx={{ width: 115 }}>
                {createMode ||
                  <IconButton aria-label="edit" onClick={editId == b.id ? onBudgetEntryEditSave : () => onBudgetEntryEdit(b.id, b.budget)}>
                    {
                      editId == b.id ?
                        <SaveIcon />
                        : editId == null &&
                        <EditIcon />
                    }
                  </IconButton>
                }
                {editId == b.id &&
                  <IconButton aria-label="cancel-edit" onClick={handleCancelEdit}>
                    <CancelIcon />
                  </IconButton>
                }
              </TableCell>
            </TableRow>
          )}
          <ClickAwayListener onClickAway={handleCancelCreate} mouseEvent='onMouseUp'>
            <TableRow>
              <TableCell>
                <Select size='small' variant='standard' disabled={!createMode} value={addCategoryId} onChange={(e) => setAddCategoryId(e.target.value)}>
                  <MenuItem value={0}>[Select a category]</MenuItem>
                  {categories.map(c =>
                    <MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>
                  )}
                </Select>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Input disabled={!createMode}
                  size="small"
                  sx={{ width: '8.0em' }}
                  value={addAmount}
                  onChange={(e) => { setAddAmount(e.target.value) }}
                  inputProps={{ style: { textAlign: 'right' } }}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                /></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <IconButton onClick={createMode ? createClickHandler : () => setCreateMode(true)}>
                  {createMode ?
                    <SaveIcon />
                    :
                    <AddIcon />
                  }
                </IconButton>
              </TableCell>
            </TableRow>
          </ClickAwayListener>
        </TableBody>
      </Table>
      {handleNext &&
        <Grid container justifyContent="flex-end" marginTop={4}>
          <Button variant="contained" onClick={handleNext} endIcon={<NavigateNextIcon />}>Continue</Button>
        </Grid>
      }
    </Fragment>
  );
}