import { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import eventBus from '../EventBus.js';
import PlaidLinkWithOAuth from './PlaidLinkWithOAuth.js';

export default function LinkStatus({ onStatusChange }) {
    const [items, setItems] = useState([]);

    const token = localStorage.getItem('token');

    useEffect(() => {
        getItems(true);

        const intervalId = setInterval(getItems, 3000);

        return () => clearInterval(intervalId);
    }, []);

    const getItems = async (init) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/accounts/items?status=pending`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const json = await res.json();
            setItems(prevItems => {
                if (!init && statusChanged(prevItems, json) && onStatusChange) {
                    onStatusChange();
                }

                return json;
            });
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const statusChanged = (prevItems, newItems) => {
        if (prevItems.length != newItems.length) {
            return true;
        }

        for (let i = 0; i < prevItems.length; i++) {
            if (prevItems[i].status != newItems[i].status) {
                return true;
            }
        }

        return false;
    }

    const editItem = async (itemId) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/accounts/items/${itemId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        if (res.ok) {
            getItems(false);
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    return (
        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
            {items.map(item =>
                <Box sx={{ display: 'flex', alignItems: 'center' }} key={item.itemId}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={
                            item.status == -1 ? 0 :
                                item.status == 0 ? 33 :
                                    item.status == 1 ? 67 : 100
                        } />
                    </Box>
                    <Box sx={{ minWidth: 200 }}>
                        {item.status == -1 && item.errorCode == 'ITEM_LOGIN_REQUIRED' ?
                            <div>
                                <img width="35px" height="35px" src={`data:image/png;base64,${item.institution?.logoBase64}`} />
                                <PlaidLinkWithOAuth itemId={item.itemId} onLinkSuccess={() => editItem(item.itemId)} />
                            </div>
                            :
                            <Typography variant="body2" color="text.secondary">{
                                item.status == -1 ? 'Error' :
                                    item.status == 0 ? 'Loading accounts...' :
                                        item.status == 1 ? 'Loading transactions...' : 'Done'
                            }</Typography>
                        }
                    </Box>
                </Box>
            )}
        </Stack>
    );
}