import { useCallback, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import {
    usePlaidLink,
    PlaidLinkOnSuccess,
    PlaidLinkOnEvent,
    PlaidLinkOnExit,
    PlaidLinkOptions,
} from 'react-plaid-link';
import eventBus from '../EventBus';

const PlaidLinkWithOAuth = ({ onLinkSuccess, itemId, loading }) => {
    const [token, setToken] = useState(null);
    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=');

    // generate a link_token when component mounts
    useEffect(() => {
        const createLinkToken = async () => {
            let url = `${process.env.REACT_APP_API_URL}/link/token/create?redirectUri=${encodeURI(window.location.href)}`;
            if (itemId) {
                url += '&itemId=' + itemId;
            }

            const token = localStorage.getItem('token');

            const res = await fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            if (res.ok) {
                const { link_token } = await res.json();
                setToken(link_token);
                // store link_token temporarily in case of OAuth redirect
                sessionStorage.setItem('link_token', link_token);
            }
            else if (res.status == 401) {
                eventBus.dispatch("sessionExpired");
            }
        }

        // do not generate a new token if page is handling an OAuth redirect.
        // instead setLinkToken to previously generated token from localStorage
        // https://plaid.com/docs/link/oauth/#reinitializing-link
        if (isOAuthRedirect) {
            const linkToken = sessionStorage.getItem('link_token');
            setToken(linkToken);
        }
        else {
            createLinkToken();
        }

        return () => {
            setToken(null);
        }
    }, []);

    const onEvent = useCallback((eventName, metadata) => {
        // log onEvent callbacks from Link
        // https://plaid.com/docs/link/web/#onevent
    }, []);
    const onExit = useCallback((error, metadata) => {
        // log onExit callbacks from Link, handle errors
        // https://plaid.com/docs/link/web/#onexit
    }, []);

    const config = {
        // token must be the same token used for the first initialization of Link
        token,
        onSuccess: onLinkSuccess,
        onEvent,
        onExit,
    };

    if (isOAuthRedirect) {
        // receivedRedirectUri must include the query params
        config.receivedRedirectUri = window.location.href;
    }

    const {
        open,
        ready,
        // error,
        // exit
    } = usePlaidLink(config);

    useEffect(() => {
        // If OAuth redirect, instantly open link when it is ready instead of
        // making user click the button
        if (isOAuthRedirect && ready) {
            open();
        }
    }, [ready, open, isOAuthRedirect]);

    // No need to render a button on OAuth redirect as link opens instantly
    return isOAuthRedirect ? (
        <></>
    ) : (
        <LoadingButton
            onClick={open} disabled={!ready}
            loading={loading}
            variant="contained"
        >
            {itemId ? "Login Required" : "Link Bank Account"}
        </LoadingButton>
    );
};

export default PlaidLinkWithOAuth;