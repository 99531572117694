import { Fragment, useState, useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Stack from '@mui/material/Stack';

import PlaidLinkWithOAuth from './PlaidLinkWithOAuth';
import OauthPopup from 'react-oauth-popup';

import useWebSocket, { ReadyState } from 'react-use-websocket';

import eventBus from '../EventBus.js';

const Accounts = ({ onNetWorthChange, user, size, handleNext }) => {

    const LIQUID_INVESTMENT_TYPES = ['brokerage', 'mutual fund'];
    const NON_LIQUID_INVESTMENT_TYPES = ['stock plan', 'roth', '401a', '401k', '403b', '457b', 'ira',
        'pension', 'retirement', 'roth 401k', 'sep ira', 'simple ira', 'trust'];

    const [accounts, setAccounts] = useState([]);
    const [cryptoWallets, setCryptoWallets] = useState([]);
    const [loading, setLoading] = useState(false);

    const token = localStorage.getItem('token');

    const { sendJsonMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => {
            console.log('Account WebSocket connection established.');
        },

        onMessage: e => {
            //const message = JSON.parse(e.data);
            const message = JSON.parse(e.data);
            console.log('web socket', message.message);
            if (message.message == 'plaid webhook received') {
                console.log(message.details);
                loadAccounts();
            }
        },

        shouldReconnect: () => true
    });

    useEffect(() => {
        if (user) {
            //console.log('sending accounts message');
            console.log('ready state', readyState);
            if (readyState == ReadyState.OPEN) {
                console.log({ userId: user?.id, body: 'accounts' });
                sendJsonMessage({ userId: user.id, body: 'accounts' });
            }
        }
    }, [readyState]);

    const loadAccounts = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/accounts`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        if (res.ok) {
            const json = await res.json();

            setLoading(false);
            setAccounts(json);

            let netWorth = 0.0;
            json.forEach(a => {
                netWorth += ['depository', 'investment'].includes(a.type) ? parseFloat(a.balance) || 0 : -parseFloat(a.balance) || 0;
            });
            onNetWorthChange && onNetWorthChange(netWorth.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }));
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const loadCrypto = () => {
        fetch(`${process.env.REACT_APP_API_URL}/crypto/wallets/list`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(json => {
                setCryptoWallets(json);
            });
    }

    useEffect(() => {
        loadAccounts();
        loadCrypto();

        return () => {
            setAccounts([]);
            setCryptoWallets([]);
        }
    }, []);

    const onCode = (code, params) => {
        let state = params.get('state');
        let body = {
            code: code,
            state: state,
            redirectUri: window.location.href
        };

        fetch(`${process.env.REACT_APP_API_URL}/link/coinbase`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(loadCrypto);
    }

    const timeSince = (date) => {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    const renderCryptoRow = (row) => {
        return (
            <TableRow key={row.id}>
                <TableCell>
                    <img width="35px" height="35px" src="https://images.ctfassets.net/c5bd0wqjc7v0/3dFdY6GvgLgCIXmBiN6eiA/d4acc5d4c5d557566cf0e46f9b58de43/icon-buy-and-sell.svg" />
                </TableCell>
                <TableCell>
                    {(row.name + ' (' + parseFloat(row.balance).toFixed(3) + ' ' + row.currency + ')')}
                </TableCell>
                <TableCell align="right">{Number(row.nativeBalance).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })}</TableCell>
                {size != 'small' &&
                    <TableCell>
                        just now
                    </TableCell>
                }
            </TableRow>
        )
    }

    const renderRow = (row) => {
        return (
            <TableRow key={row.id}>
                <TableCell>
                    {row.plaid_item?.institution ? <img width="35px" height="35px" src={`data:image/png;base64,${row.plaid_item?.institution.logoBase64}`} /> : ''}
                </TableCell>
                <TableCell>
                    {row.official_name || (row.name + ' (**' + row.mask + ')')}
                </TableCell>
                <TableCell align="right">{Number(row.balance).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })}</TableCell>
                {size != 'small' &&
                    <TableCell>
                        {timeSince(Date.parse(row.plaid_item.lastSync))} ago
                    </TableCell>
                }
            </TableRow>
        )
    }

    const onLinkSuccess = (public_token, metadata) => {
        console.log(public_token);

        fetch(`${process.env.REACT_APP_API_URL}/link/item/create`, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                public_token: public_token
            })
        })
            .then(res => {
                if (res.status == 200) {
                    setLoading(true);
                }
                else if (res.status == 401) {
                    eventBus.dispatch("sessionExpired");
                }
            })
    };

    const depositoryAccounts = accounts.filter(a => a.type === 'depository');
    const creditAccounts = accounts.filter(a => a.type === 'credit');
    const liquidInvestments = accounts.filter(a => LIQUID_INVESTMENT_TYPES.includes(a.subType));
    const nonLiquidInvestments = accounts.filter(a => NON_LIQUID_INVESTMENT_TYPES.includes(a.subType));
    const loanAccounts = accounts.filter(a => a.type === 'loan');

    return (
        <Fragment>
            <Paper>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {size == 'small' ?
                                accounts.slice(0, 5).map(a => renderRow(a)) : // TODO add crypto?
                                <>
                                    {depositoryAccounts.length > 0 &&
                                        <TableRow><TableCell colSpan={4} style={{ fontWeight: "bold" }}>On Deposit</TableCell></TableRow>
                                    }
                                    {depositoryAccounts.map(row => renderRow(row))}
                                    {creditAccounts.length > 0 &&
                                        <TableRow><TableCell colSpan={4} style={{ fontWeight: "bold" }}>Credit</TableCell></TableRow>
                                    }
                                    {creditAccounts.map(row => renderRow(row))}
                                    {liquidInvestments.length > 0 &&
                                        <TableRow><TableCell colSpan={4} style={{ fontWeight: "bold" }}>Liquid Investments</TableCell></TableRow>
                                    }
                                    {liquidInvestments.map(row => renderRow(row))}
                                    {cryptoWallets.length > 0 &&
                                        <TableRow><TableCell colSpan={4} style={{ fontWeight: "bold" }}>Crypto</TableCell></TableRow>
                                    }
                                    {cryptoWallets.map(row => renderCryptoRow(row))}
                                    {nonLiquidInvestments.length > 0 &&
                                        <TableRow><TableCell colSpan={4} style={{ fontWeight: "bold" }}>Non-Liquid and Retirement Investments</TableCell></TableRow>
                                    }
                                    {nonLiquidInvestments.map(row => renderRow(row))}
                                    {loanAccounts.length > 0 &&
                                        <TableRow><TableCell colSpan={4} style={{ fontWeight: "bold" }}>Loans</TableCell></TableRow>
                                    }
                                    {loanAccounts.map(row => renderRow(row))}
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {size != 'small' &&
                <Grid container justifyContent="space-between" marginTop={2}>
                    <Grid item>
                        <Stack direction="row" spacing={1}>
                            <PlaidLinkWithOAuth onLinkSuccess={onLinkSuccess} loading={loading} />
                            {/* <OauthPopup
                                url={`${process.env.REACT_APP_API_URL}/auth/coinbase?jwt=${token}&redirectUri=${encodeURI(window.location.href)}`}
                                onCode={onCode}
                                onClose={() => { }}
                            >
                                <Button variant="contained">Link Coinbase Account</Button>
                            </OauthPopup> */}
                        </Stack>
                    </Grid>
                    <Grid item>
                        {handleNext &&
                            <Button variant="contained" onClick={handleNext} endIcon={<NavigateNextIcon />}>Continue</Button>
                        }
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
}

export default Accounts;