import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import imgAllocations from '../images/perdiem_allocations.png';
import useStyles from '../styles/styles';

const LandingInvestments = () => {
  const classes = useStyles();

  return (
    <Box className={classes.aboutUsContainer}>
      <Grid container spacing={6} className={classes.gridContainer}>

        <Grid item xs={12} md={6}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Monitor your investments
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            All of your investments, including taxable brokerage accounts, IRAs, etc are automatically imported. See how movements in the market impact your FI age, and ensure that your allocations are optimized for your FIRE plan.
          </Typography>
        </Grid>

        <Grid item xs={12} md={5}>
          <img src={imgAllocations} alt="Investment Allocations" className={classes.largeImage} />
        </Grid>

      </Grid>
    </Box>
  );
};

export default LandingInvestments;