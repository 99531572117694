import { Fragment, useState, useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

import eventBus from '../EventBus.js';

import Title from './Title';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BalanceIcon from '@mui/icons-material/Balance';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const formatCurrency = (value) => {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });
}

const formatNumber = (value) => {
  return Number(value.replace(/[^\d.-]/g, '') || '').toLocaleString('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0
  });
}

const Goals = ({ handleNext }) => {

  const [data, setData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [editPlan, setEditPlan] = useState();

  const token = localStorage.getItem('token');

  const handleEditClick = (p) => {
    console.log('editing plan ' + p.id);
    setEditPlan(p);

    setModalOpen(true);
  }

  const handleAddClick = () => {
    setModalOpen(true);
    setEditPlan();
  }

  const onCloseModal = () => {
    setModalOpen(false);
  }

  const onCreateComplete = (json) => {
    setData(prevRows => {
      return [...prevRows, json].sort((a, b) => a.year - b.year);
    });
  }

  const onEditComplete = (json) => {
    // 1. Make a shallow copy of the items
    let planRows = [...data];

    // 2. Make a shallow copy of the item you want to mutate
    let rowIndex = planRows.findIndex(b => b.id == editPlan.id);
    //let item = { ...planRows[rowIndex] };
    //console.log(item);

    // 3. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    //plan.id = editPlanId;
    planRows[rowIndex] = json;
    //console.log(budgetRows);

    // 4. Set the state to our new copy
    setData(planRows.sort((a, b) => a.year - b.year));
  }

  const onDeleteComplete = () => {
    console.log('on delete complete');
    setData(prevRows => {
      return [...prevRows.filter(p => p.id != editPlan.id)];
    });
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/goals`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
      .then(res => {
        if (res.ok) {
          res.json()
            .then(json => {
              setData(json);
            });
        }
        else if (res.status == 401) {
          eventBus.dispatch("sessionExpired");
        }
      });

    return () => {
      setData([]);
    }
  }, []);

  return (
    <Fragment>
      <Title>Milestones</Title>

      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}>
        {data.map((p, i) =>
          <TimelineItem key={p.id} sx={{ minHeight: 85 }}>
            <TimelineOppositeContent color="textSecondary" sx={{ my: 1.5 }}>
              {i == 0 || p.year != data[i - 1].year ? p.year : ''}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                {p.type == 'purchase' ?
                  <ShoppingCartIcon /> :
                  p.type == 'quit a job' ?
                    <BeachAccessIcon /> :
                    p.type == 'budget change' ?
                      <BalanceIcon /> :
                      <TrendingUpIcon />
                }
              </TimelineDot>
              {i < data.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent
              onClick={() => handleEditClick(p)}
              sx={{ position: 'relative', top: -13, m: '0 auto', cursor: 'pointer' }}>
              <Box sx={{ position: 'absolute', top: '50%', transform: 'translate(0, -50%)' }}>
                  <Typography variant="h6" component="p">{p.description}</Typography>
                  <Typography>
                    {
                      p.type == 'purchase' || p.type == 'windfall' ?
                        formatCurrency(p.amount) :
                        p.type == 'budget change' ?
                          'Change ' + p.budgetCategory.name + ' to ' + formatCurrency(p.amount) : ''
                    }
                  </Typography>
              </Box>
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>

      <Stack direction="row" spacing={1} marginTop={5} justifyContent="space-between">
        <Button variant="contained" onClick={handleAddClick}>New Milestone</Button>
        {handleNext &&
          <Button variant="contained" onClick={handleNext} endIcon={<NavigateNextIcon />}>Continue</Button>
        }
      </Stack>

      <AddMilestoneModal
        modalOpen={modalOpen}
        editPlan={editPlan}
        onClose={onCloseModal}
        onCreate={onCreateComplete}
        onEdit={onEditComplete}
        onDelete={onDeleteComplete}
      />
    </Fragment>
  )
}

const AddMilestoneModal = ({ modalOpen, editPlan, onClose, onCreate, onEdit, onDelete }) => {

  const token = localStorage.getItem('token');

  const [incomeStreams, setIncomeStreams] = useState([]);
  const [budgetEntries, setBudgetEntries] = useState([]);

  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [year, setYear] = useState('2023');
  const [planType, setPlanType] = useState('');
  const [incomeToQuit, setIncomeToQuit] = useState('');
  const [budgetEntry, setBudgetEntry] = useState('');

  const [isdisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (modalOpen && editPlan) {
      setPlanType(editPlan.type);
      setDescription(editPlan.description);
      setYear(editPlan.year);
      setAmount(editPlan.amount);

      setBudgetEntry(editPlan.budgetCategory?.id);
      setIncomeToQuit(editPlan.incomeStream?.id);

      setIsDisabled(false);
    }
  }, [editPlan]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/income`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
      .then(res => {
        if (res.ok) {
          res.json()
            .then(json => {
              const wages = json
                .filter(i => i.type == 'Income - Wages')
                .map(i => ({ value: i.id, text: i.name }));
              setIncomeStreams(wages);
            });
        }
        else if (res.status == 401) {
          eventBus.dispatch("sessionExpired");
        }
      });

    return () => {
      setIncomeStreams([]);
    }
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/budgets/entries`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
      .then(res => {
        if (res.ok) {
          res.json()
            .then(json => {
              const activeEntries = json.rows
                .filter(b => b.budget > 0)
                .map(b => ({
                  value: b.id,
                  text: b.subcategory + ' (' + formatCurrency(b.budget) + ')',
                  amount: b.budget
                }));
              setBudgetEntries(activeEntries);
            });
        }
        else if (res.status == 401) {
          eventBus.dispatch("sessionExpired");
        }
      });

    return () => {
      setBudgetEntries([]);
    }
  }, []);

  const handleCloseModal = () => {
    // clear modal state
    setDescription('');
    setAmount(0);
    setYear('');
    setPlanType('');
    setIncomeToQuit('');
    setBudgetEntry('');

    onClose();
  }

  const handlePlanTypeChange = (e) => {
    setPlanType(e.target.value);
    if (e.target.value === '') {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }

  const handleBudgetEntryChange = (e) => {
    setBudgetEntry(e.target.value);
    const currentBudgetAmount = budgetEntries.find(b => b.value == e.target.value).amount;
    if (amount == 0 || amount == '') {
      setAmount(currentBudgetAmount || '');
    }
  }

  const handleSaveClick = async () => {
    let plan = {
      type: planType,
      description: description,
      amount: amount.replace(/[^\d.-]/g, ''),
      year: year,
      incomeStreamId: incomeToQuit == '' ? null : incomeToQuit,
      budgetCategoryId: budgetEntry == '' ? null : budgetEntry
    };

    let url = `${process.env.REACT_APP_API_URL}/goals`;
    if (editPlan) {
      url += '/' + editPlan.id;
    }

    const res = await fetch(url, {
      method: editPlan ? 'PUT' : 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(plan)
    });

    if (res.ok) {
      const json = await res.json();
      if (res.status == 201) { // created
        onCreate(json);
      }
      else if (res.status == 200) { // updated
        onEdit(json);
      }

      handleCloseModal();
    }
    else if (res.status == 401) {
      eventBus.dispatch("sessionExpired");
    }
  }

  const handleDeleteClick = async () => {
    console.log('handle delete click');

    const res = await fetch(`${process.env.REACT_APP_API_URL}/goals/${editPlan.id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });

    if (res.ok) {
      console.log('deleted');
      onDelete();

      handleCloseModal();
    }
    else if (res.status == 401) {
      eventBus.dispatch("sessionExpired");
    }
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Stack spacing={2}>
          <Stack marginBottom={3}>
            <Typography variant="h6">
              Make no little plans. They have no magic to stir men's blood!
            </Typography>
            <Typography variant="h8" sx={{ fontStyle: 'italic' }}>
              &#8212; Daniel Burnham
            </Typography>
          </Stack>
          <FormControl>
            <InputLabel htmlFor="my-input">Type</InputLabel>
            <Select id="my-select" aria-describedby="my-helper-text" value={planType} onChange={handlePlanTypeChange}>
              <MenuItem value="purchase">Make a large purchase, pay off a debt, or set aside money</MenuItem>
              <MenuItem value="quit a job">Quit a job (before reaching FI)</MenuItem>
              <MenuItem value="budget change">Change a budget entry (e.g. educational expenses, travel)</MenuItem>
              <MenuItem value="windfall">Receive a windfall (e.g. inheritance, sale of business)</MenuItem>
            </Select>
          </FormControl>
          {planType == 'quit a job' &&
            <FormControl>
              <InputLabel htmlFor="my-input">Job to quit</InputLabel>
              <Select id="my-select" aria-describedby="my-helper-text" value={incomeToQuit} onChange={(e) => { setIncomeToQuit(e.target.value) }}>
                {incomeStreams.map(i => <MenuItem key={i.value} value={i.value}>{i.text}</MenuItem>)}
              </Select>
            </FormControl>
          }
          {planType == 'budget change' &&
            <FormControl>
              <InputLabel htmlFor="my-input">Budget entry</InputLabel>
              <Select id="my-select" aria-describedby="my-helper-text" value={budgetEntry} onChange={handleBudgetEntryChange}>
                {budgetEntries.map(i => <MenuItem key={i.value} value={i.value}>{i.text}</MenuItem>)}
              </Select>
            </FormControl>
          }
          <Fragment>
            <FormControl>
              <InputLabel htmlFor="my-input">Description</InputLabel>
              <Input id="my-input" aria-describedby="my-helper-text" value={description} onChange={(e) => { setDescription(e.target.value) }} />
              <FormHelperText id="my-helper-text">Example: Family trip to Europe</FormHelperText>
            </FormControl>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="my-input">Year</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" value={year} onChange={(e) => { setYear(e.target.value) }} />
                <FormHelperText id="my-helper-text">When you expect this change to happen</FormHelperText>
              </FormControl>
              {planType == 'quit a job' ?
                <FormControl fullWidth></FormControl>
                :
                <FormControl fullWidth>
                  <InputLabel htmlFor="my-input">New amount</InputLabel>
                  <Input
                    id="my-input"
                    aria-describedby="my-helper-text"
                    value={amount} onChange={(e) => { setAmount(formatNumber(e.target.value)); }}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                  <FormHelperText id="my-helper-text"></FormHelperText>
                </FormControl>
              }
            </Stack>
          </Fragment>
          <Box justifyContent="space-between" display="flex">
            <Button variant="contained" disabled={isdisabled} onClick={handleSaveClick}>Save</Button>
            {editPlan &&
              <Button variant="contained" onClick={handleDeleteClick} color="error">Delete</Button>
            }
          </Box>
        </Stack>
      </Box>
    </Modal>
  )
}

export default Goals;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};