import { Fragment, useState, useEffect } from 'react';

import { useOutletContext } from 'react-router-dom';

import eventBus from '../EventBus.js';

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

import Grid from '@mui/material/Grid';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const IncomeStreams = ({ handleNext}) => {

    const [income, setIncome] = useState([]);
    const [ssLoading, setSsLoading] = useState(false);

    const token = localStorage.getItem('token');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/income`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then(res => {
                if (res.ok) {
                    res.json()
                        .then(json => {
                            if (json.length > 0) {
                                console.log('setting income');
                                console.log(json);
                                setIncome(json);
                            }
                            else {
                                autoCreateFromTransactions();
                            }
                        });
                }
                else if (res.status == 401) {
                    eventBus.dispatch("sessionExpired");
                }
            });

        return () => {
            setIncome([]);
        }
    }, []);

    const incomeColumns = [
        {
            field: 'name',
            headerName: 'Name',
            editable: true,
            flex: 3,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'amount',
            headerName: 'Monthly',
            editable: true,
            valueGetter: (params) => {
                return Number(params.row.amount).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0
                });
            },
            align: 'right',
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'growthRate',
            headerName: 'Est Growth',
            editable: true,
            valueGetter: (params) => {
                return Number(params.row.growthRate).toLocaleString('en-US', {
                    style: 'percent',
                    maximumFractionDigits: 1
                });
            },
            align: 'right',
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'type',
            headerName: 'Type',
            type: 'singleSelect',
            valueOptions: ['Wages', 'Pension', 'Dividends', 'Interest Earned', 'Unemployment', 'Other'],
            editable: true,
            valueGetter: (params) => params.value.substring('Income - '.length),
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'begin',
            headerName: 'Begin Year',
            editable: true,
            valueGetter: (params) => params.value?.slice(0, 4),
            align: 'right',
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'actions',
            type: 'actions',
            width: 50,
            getActions: (params) => [
                <GridActionsCellItem icon={<DeleteIcon />}
                    label="Delete"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteIncome(params.id);
                    }}
                />,
            ],
            sortable: false,
            disableColumnMenu: true
        }
    ];

    const autoCreateFromTransactions = async () => {

        const res = await fetch(`${process.env.REACT_APP_API_URL}/income/defaults`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        });

        if (res.ok) {
            const newIncome = await res.json();

            setIncome(prevRows => {
                return [...prevRows, ...newIncome];
            });
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const estimateSocialSecurity = async () => {
        setSsLoading(true);
        const res = await fetch(`${process.env.REACT_APP_API_URL}/income/social-security-estimates`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        });

        if (res.ok) {
            const newIncome = await res.json();

            setIncome(prevRows => {
                return [...prevRows, newIncome];
            });

            setSsLoading(false);
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const createIncome = async () => {

        const res = await fetch(`${process.env.REACT_APP_API_URL}/income`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })

        if (res.ok) {
            const json = await res.json();
            setIncome(prevRows => {
                return [...prevRows, json];
            });
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const onIncomeEdit = async (newRow) => {
        console.log('editing');

        newRow.amount = newRow.amount.replace(/[^\d.-]/g, '');
        if (!newRow.type.startsWith('Income - ')) {
            newRow.type = 'Income - ' + newRow.type;
        }

        console.log(newRow);

        const res = await fetch(`${process.env.REACT_APP_API_URL}/income/${newRow.id}`, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newRow)
        });

        if (res.ok) {
            //apiRef.current.updateRows([newRow]);

            setIncome((prevRows) => {
                return prevRows.map((row, index) =>
                    row.id == newRow.id ? newRow : row,
                );
            });

            return newRow;
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    const deleteIncome = async (id) => {
        let token = localStorage.getItem('token');

        const res = await fetch(`${process.env.REACT_APP_API_URL}/income/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        if (res.ok) {
            setIncome(prevRows => prevRows.filter(row => row.id !== id));
        }
        else if (res.status == 401) {
            eventBus.dispatch("sessionExpired");
        }
    }

    return (
        <Fragment>
            {/* <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Name</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Monthly</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Est Growth</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Type</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Begin Year</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(b =>
                        <TableRow key={b.id} sx={{ height: 53 }}>
                            <TableCell>{b.subcategory}</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                {
                                    editId == b.id ?
                                        <Input
                                            size="small"
                                            sx={{ width: '8.0em' }}
                                            value={editAmount}
                                            onChange={(e) => { setEditAmount(e.target.value) }}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        />
                                        :
                                        Number(b.budget).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            maximumFractionDigits: 0
                                        })
                                }
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>{Number(b.current).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                maximumFractionDigits: 0
                            })}</TableCell>
                            <TableCell sx={{ width: 115 }}>
                                {createMode ||
                                    <IconButton aria-label="edit" onClick={editId == b.id ? onBudgetEntryEditSave : () => onBudgetEntryEdit(b.id, b.budget)}>
                                        {
                                            editId == b.id ?
                                                <SaveIcon />
                                                : editId == null &&
                                                <EditIcon />
                                        }
                                    </IconButton>
                                }
                                {editId == b.id &&
                                    <IconButton aria-label="cancel-edit" onClick={handleCancelEdit}>
                                        <CancelIcon />
                                    </IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                    <ClickAwayListener onClickAway={handleCancelCreate} mouseEvent='onMouseUp'>
                        <TableRow>
                            <TableCell>
                                <Select size='small' variant='standard' disabled={!createMode} value={addCategoryId} onChange={(e) => setAddCategoryId(e.target.value)}>
                                    <MenuItem value={0}>[Select a category]</MenuItem>
                                    {categories.map(c =>
                                        <MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>
                                    )}
                                </Select>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                <Input disabled={!createMode}
                                    size="small"
                                    sx={{ width: '8.0em' }}
                                    value={addAmount}
                                    onChange={(e) => { setAddAmount(e.target.value) }}
                                    inputProps={{ style: { textAlign: 'right' } }}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                /></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <IconButton onClick={createMode ? createClickHandler : () => setCreateMode(true)}>
                                    {createMode ?
                                        <SaveIcon />
                                        :
                                        <AddIcon />
                                    }
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </ClickAwayListener>
                </TableBody>
            </Table> */}


            <DataGrid
                autoHeight
                rows={income}
                columns={incomeColumns}
                processRowUpdate={onIncomeEdit}
                onProcessRowUpdateError={(e) => { console.error(e) }}
                sx={{
                    mb: 2,
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    },
                    '.MuiDataGrid-columnHeaderTitleContainer': {
                        justifyContent: 'center',
                    }
                }}
                hideFooter
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%"
                            paddingLeft={10}
                            paddingRight={10}
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center">
                            Looks like you don't have any income streams set up yet.
                            After you've connected all your accounts, and your income history has been
                            loaded, you can autocreate recurring income streams or create manually using
                            the buttons below.
                        </Stack>
                    )
                }}
            />
            <Grid container justifyContent="space-between" marginTop={2}>
                <Grid item>
                    <Stack direction="row" spacing={1}>
                        <Button variant="contained" onClick={createIncome}>New Income Stream</Button>
                        {false && <Button variant="outlined" onClick={autoCreateFromTransactions}>Infer from Transactions</Button>}
                        <LoadingButton variant="outlined" loading={ssLoading} onClick={estimateSocialSecurity}>Estimate Social Security</LoadingButton>
                    </Stack>
                </Grid>
                <Grid item>
                    {handleNext &&
                        <Button variant="contained" onClick={handleNext} endIcon={<NavigateNextIcon />}>Continue</Button>
                    }
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default IncomeStreams;