
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';

import useFetch from '../hooks/useFetch.js';

export default function DrawSchedule() {
    const { data } = useFetch('fire/plan');

    const formatCurrency = (value) => {
        if(!value) { return null }

        let formatted = parseFloat(value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
        });

        return formatted;
    }

    return (
        <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table sx={{ minWidth: 650 }} stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>Income</TableCell>
                        <TableCell>Expenses</TableCell>
                        <TableCell>Taxable</TableCell>
                        <TableCell>Tax Deferred</TableCell>
                        <TableCell>Tax Exempt</TableCell>
                        <TableCell>Tax Paid</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.ledgerEntries?.map((d, i) =>
                        <TableRow key={i}>
                            <TableCell>{d.year}</TableCell>
                            <TableCell>{formatCurrency(d.income)}</TableCell>
                            <TableCell>{formatCurrency(d.expenses)}</TableCell>
                            <TableCell>{formatCurrency(d.taxable)}</TableCell>
                            <TableCell>{formatCurrency(d.taxDeferred)}</TableCell>
                            <TableCell>{formatCurrency(d.taxExempt)}</TableCell>
                            <TableCell>{formatCurrency(d.taxPaid)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}