import React from 'react';
import { Typography, Box, Card, CardContent, Grid } from '@mui/material';
import { Avatar } from '@mui/material';
import useStyles from '../styles/styles';
import sharon from '../images/sharon.jpg';
import vicki from '../images/vicki.jpg';

const Testimonial = () => {
  const classes = useStyles();
  const reviews = [
    {
      id: 1,
      name: 'Sharon',
      statement:
        'Per Diem gave me the motivation and the practical tools to make my early retirement dream a reality. It’s easy to use even if you’re not tech or financially savvy.',
      image_url:
        sharon,
      position: 'Civil rights attorney',
    },
    {
      id: 2,
      name: 'Vicki',
      statement:
        'My husband and I are in our sixties and have been considering the possibility of retirement since our youngest daughter left the nest. We were reticent to pay for a financial advisor. An associate recommended Per Diem. We found it to be affordable, user-friendly, and comprehensive. Florida, here we come!',
      image_url:
        vicki,
      position: 'Retired school teacher',
    },
    {
      id: 3,
      name: 'Ruthie',
      statement:
        'After scouring through myriad financial planning apps, found this GEM. Love that you can test different retirement scenarios. Personally have not found anything like it in the market.',
      image_url:
        'https://d1hbpr09pwz0sk.cloudfront.net/profile_pic/ruthie-nissim-74693f23',
      position: 'Sales Executive',
    },
  ];
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: '20px',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
      }}
    >
      <Grid container spacing={2}>
        {reviews.map((review) => (
          <Grid item sm={12} md={4} key={review.id}>
            <Card className={classes.testimonialCard}>
              <CardContent>
                <Typography className={classes.testimonialStatement}>
                  "{review.statement}"
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Avatar
                    src={review.image_url}
                    size="large"
                    className={classes.avatar}
                  />
                  <Box>
                    <Typography>{review.name}</Typography>
                    <Typography className={classes.testimonialPosition}>
                      {review.position}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonial;